<div class="wrapper row4 bgded overlay" style="background-image:url('../../assets/images/1500x500 copy.jpg');">
  <footer id="footer" class="hoc clear">
    <div class="center">
        <h3 class="heading">Tom Pickren.io</h3>
        <ul class="center" style="list-style-type:none; margin-right: 50px;">
          <li><a href="https://www.facebook.com/tom.pickren" target="_blank" alt="Facebook" title="Facebook"><i class="fa fa-facebook"></i></a></li>
          <li><a href="https://github.com/tpickren92" target="_blank" alt="Github" title="Github"><i class="fa fa-github"></i></a></li>
          <li><a href="https://www.linkedin.com/in/tom-pickren-4b533672/" target="_blank" alt="LinkedIn" title="LinkedIn"><i class="fa fa-linkedin"></i></a></li>
          <li><a href="mailto:tpickren@gmail.com" target="_blank" alt="email" title="email"><i class="fa fa-envelope"></i></a></li>
        </ul>
      <br>
      <p class="center" id="copyrightText">Copyright &copy; {{ currentYear }} - All Rights Reserved</p>
      <a id="backtotop" href="#top"><i class="fa fa-chevron-up"></i></a>
    </div>
  </footer>
</div>
