<div class="bgded overlay" style="background-image:url('../../assets/images/1500x500 copy.jpg');">

  <div id="pageintro" class="hoc clear">
    <div class="flexslider basicslider">
      <ul class="headerSlides">
        <li>
          <article>
            <h3 class="heading">Let's chat!</h3>
            <footer id="contactFooter">
              <div class="contact-btn">
                <a class="btn" href="mailto:tpickren@gmail.com" target="_blank" alt="Email" title="Email">Email</a>
              </div>
              <div class="contact-btn">
              <a class="btn" href="../../assets/resume/Tom Pickren Resume.pdf" target="_blank" alt="Resume" title="Resume">Resume
                <i class="fa fa-external-link"></i></a>
              </div>
              <div class="contact-btn">
                <a class="btn" href="https://www.linkedin.com/in/tom-pickren-4b533672/" target="_blank" alt="LinkedIn" title="LinkedIn">LinkedIn
                  <i class="fa fa-external-link"></i></a>
              </div>
            </footer>
          </article>
        </li>
      </ul>
    </div>
  </div>

</div>

<div class="wrapper row3">
  <main class="hoc container clear">
    <div class="btmspace-80 center">
      <div>
        <h3 class="nospace" id="bodyHeader">Here to Help</h3>
        <p class="nospace">I have a multitude of skills ranging from design and development of sleek,
          responsive websites to graphic design and copywriting. I'm always open to new challenges and I
          jump at any chance I have to learn something new.
        </p>
        <br>
        <br>
        <p>
          Feel free to contact me for any reason.
        </p>
      </div>
    </div>
  </main>
</div>



<!--<div class="wrapper row3">-->
<!--  <main class="hoc container clear">-->

<!--    <div class="btmspace-80 center">-->
<!--      <br>-->
<!--      <p class="nospace">I have a multitude of skills ranging from design and development of sleek,-->
<!--        responsive websites to graphic design and copywriting.</p>-->
<!--      <br>-->
<!--      <br>-->
<!--      <p>-->
<!--        Feel free to contact me for any reason.-->
<!--      </p>-->
<!--      <br>-->
<!--      <br>-->
<!--      <br>-->



<!--    </div>-->
<!--    <div class="clear"></div>-->
<!--  </main>-->
<!--</div>-->
